<template>
  <div>
    <div v-if="appStore.isUnderMaintenance">
      <HeaderBar />
      <div class="d-flex">
        <div class="w-100 default-layout_router">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  // Store
  const appStore = useAppStore()
</script>
